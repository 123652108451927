<template>
  <div id="third_Party_order">
    <a-spin :spinning="spinning" size="large" :tip="tip">
      <div class="isDisplay">
        <div class="top">
          <a-form layout="inline">
            <a-form-item>
              <a-button icon="plus" class="margin_right20" type="primary" @click="addClick('1')">添加</a-button>
            </a-form-item>
          </a-form>
        </div>
        <!-- 表格 -->
        <a-table :columns="columns" :data-source="tableData" :pagination="false" :row-key="record => record.id"
          :loading="tableLoading">
          <span slot="enableFlag" slot-scope="enableFlag, record">
            <a-badge v-if="record.enableFlag == 0" status="warning" text="已下架" />
            <a-badge v-else status="success" text="已上架" />
          </span>
          <span slot="operation" slot-scope="operation, record">
            <a class="margin_right10" type="primary" @click="editClick(record)">编辑</a>
          </span>
        </a-table>
      </div>
    </a-spin>
    <router-view />
  </div>
</template>

<script>
import { gameList } from "@/request/api/appletsManage";
import MyPagination from "@/components/pagination/MyPagination";
import moment from "moment";
export default {
  components: { MyPagination },
  created () {
    this.getInitFn()
  },

  data () {
    return {
      tip: "",
      spinning: false,
      tableLoading: false,
      columns: [
        {
          title: "名称",
          dataIndex: "title",
          key: "title",
        },
        {
          title: "视频链接",
          dataIndex: "fileUrl",
          key: "fileUrl",
        },
        {
          title: "日期",
          dataIndex: "effectiveDate",
          key: "effectiveDate",
          width: "120px"
        },
        {
          title: "操作",
          width: "70px",
          dataIndex: "operation",
          key: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      tableData: [],
    };
  },

  methods: {
    moment,
    dateTimeChange (val, dateString) {
      this.form.effectiveDay = dateString
    },

    // 点击新增按钮
    addClick () {
      this.$router.push(`/appletsManage/classicsYdtEdit/0`)
    },

    // 编辑编辑按钮
    editClick (record) {
      this.$router.push(`/appletsManage/classicsYdtEdit/${record.id}`)
    },

    // 分页功能切换的回调
    showSizeChangeFn (current, pageSize) {
      this.pageNo = current;
      this.pageSize = pageSize;
      this.getInitFn();
    },

    // 原属数据获取
    getInitFn () {
      gameList({
        pageSize: this.pageSize,
        pageNo: this.pageNo
      }).then(({ code, data }) => {
        this.tableLoading = false
        this.tableData = data;
      });
    }
  },
};
</script>

<style lang="less" scoped>
#third_Party_order {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}

.avatar {
  width: 100px;
}
</style>

