// 小程序相关接口
import request from '@/request/request'

// 视频列表
export const VideoList = (params) => request.post(`/adminv2/miniprogram/videoList`, params);

// 添加视频
export const AddVideoForProgram = (params) => request.post(`/adminv2/miniprogram/addVideoForProgram`, params);

// banner列表
export const listBanner = (params) => request.post(`/adminv2/applet/banner/list`, params);

// 添加banner
export const addBanner = (params) => request.post(`/adminv2/applet/banner/add`, params);

// banner详情
export const detailBanner = (params) => request.post(`/adminv2/applet/banner/detail`, params);

// 修改banner
export const updateBanner = (params) => request.post(`/adminv2/applet/banner/update`, params);

// 删除banner
export const deleteBanner = (params) => request.post(`/adminv2/applet/banner/delete`, params);

// 修改banner启用状态
export const enableFlagBanner = (params) => request.post(`/adminv2/applet/banner/enableFlag`, params);

// 添加私域入口
export const privateAdd = (params) => request.post(`/adminv2/applet/privateAd/add`, params);

// 私域入口详情
export const privateDetail = (params) => request.post(`/adminv2/applet/privateAd/detail`, params);

// 修改私域入口
export const privateUpdate = (params) => request.post(`/adminv2/applet/privateAd/update`, params);

// 添加直播介绍
export const liveDescAdd = (params) => request.post(`/adminv2/applet/liveDesc/add`, params);

// 修改直播介绍
export const liveDescUpdate = (params) => request.post(`/adminv2/applet/liveDesc/update`, params);

// 直播介绍列表
export const liveDescList = (params) => request.post(`/adminv2/applet/liveDesc/list`, params);

// 删除直播介绍
export const liveDescDelete = (params) => request.post(`/adminv2/applet/liveDesc/delete`, params);

// 分享海报列表
export const sharePosterList = (params) => request.post(`/adminv2/applet/sharePoster/list`, params);

// 添加分享海报
export const sharePosterAdd = (params) => request.post(`/adminv2/applet/sharePoster/add`, params);

// 分享海报详情
export const sharePosterDetail = (params) => request.post(`/adminv2/applet/sharePoster/detail`, params);

// 修改分享海报
export const sharePosterUpdate = (params) => request.post(`/adminv2/applet/sharePoster/update`, params);

// 修改分享海报状态
export const sharePosterEnableFlag = (params) => request.post(`/adminv2/applet/sharePoster/enableFlag`, params);

// 删除分享海报
export const sharePosterDelete = (params) => request.post(`/adminv2/applet/sharePoster/delete`, params);

// 查询申怡佳选显示状态
export const getShowSwitch = (params) => request.post(`/adminv2/applet/liveDesc/getShowSwitch`, params);

// 设置申怡佳选是否显示
export const isShowSwitchL = (params) => request.post(`/adminv2/applet/liveDesc/isShowSwitch`, params);


// 限时活动接口
// 限时活动列表
export const activeList = (params) => request.post(`/adminv2/applet/active/list`, params);
// 添加限时活动
export const activeAdd = (params) => request.post(`/adminv2/applet/active/add`, params);
// 修改限时活动
export const activeUpdate = (params) => request.post(`/adminv2/applet/active/update`, params);
// 修改限时活动状态
export const activeEnableFlag = (params) => request.post(`/adminv2/applet/active/enableFlag`, params);
// 删除限时活动
export const activeDelete = (params) => request.post(`/adminv2/applet/active/delete`, params);

// 活动接口
// 看法列表
export const commentList = (params) => request.post(`/adminv2/activities/shuihu/commentList`, params);
// 修改看法状态
export const commentEnableFlag = (params) => request.post(`/adminv2/activities/shuihu/commentEnableFlag`, params);

// 话题列表
export const topicList = (params) => request.post(`/adminv2/activities/shuihu/topicList`, params);
// 添加话题
export const addTopic = (params) => request.post(`/adminv2/activities/shuihu/addTopic`, params);
// 编辑话题
export const updateTopic = (params) => request.post(`/adminv2/activities/shuihu/updateTopic`, params);
// 修改话题状态
export const topicEnableFlag = (params) => request.post(`/adminv2/activities/shuihu/topicEnableFlag`, params);

// 问答列表
export const qaList = (params) => request.post(`/adminv2/activities/shuihu/qaList`, params);
// 添加问答
export const addQa = (params) => request.post(`/adminv2/activities/shuihu/addQa`, params);
// 编辑问答
export const updat = (params) => request.post(`/adminv2/activities/shuihu/updateQa`, params);

// 四大名著21天优学营
// 列表
export const answerList = (params) => request.post(`/adminv2/activities/shuihu/answerList`, params);
// 新增/修改
export const saveAnswer = (params) => request.post(`/adminv2/activities/shuihu/saveAnswer`, params);
// 详情
export const getAnswerInfo = (params) => request.post(`/adminv2/activities/shuihu/getAnswerInfo`, params);
// 添加二维码
export const activeQr = (params) => request.post(`/adminv2/activities/shuihu/activeQr`, params);
// 开关
export const isShowSwitch = (params) => request.post(`/adminv2/activities/shuihu/isShowSwitch`, params);
// 开关信息
export const getShowSwitc = (params) => request.post(`/adminv2/activities/shuihu/getShowSwitch`, params);
// 二维码地址
export const getActiveQr = (params) => request.post(`/adminv2/activities/shuihu/getActiveQr`, params);

// 名著一点通
// 动画答题列表
export const gameList = (params) => request.post(`/adminv2/activities/shuihu/gameList`, params);
// 添加动画答题
export const saveGame = (params) => request.post(`/adminv2/activities/shuihu/saveGame`, params);
// 动画答题详情
export const getGameInfo = (params) => request.post(`/adminv2/activities/shuihu/getGameInfo`, params);